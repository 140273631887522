<template>
  <div class="home">
    <Breadcrumb :title="$route.meta.title" />
    
    <div class="welcomeBox">

      <h1>歡迎進入Api管理系統</h1>

    </div>
    <div class="listBox" id="contentBody" style="line-height:30px;">
        <div class="functionButton">
          <el-button type="primary" @click="accMgt()" style="font-size:1.5rem;">
            <font-awesome-icon icon="users" size="3x" style="margin:25px;"  /><br/>帳號管理</el-button>
          <el-button type="success" @click="authMgt()" style="font-size:1.5rem;" >
            <font-awesome-icon icon="atom"  size="3x" style="margin:25px;"  /><br/>認證及授權管理
          </el-button>
          <el-button type="warning" @click="repMgt()" style="font-size:1.5rem;">
            <font-awesome-icon icon="scroll"  size="3x" style="margin:25px;" /><br/>管理報表
          </el-button>
        </div>

    </div>


  </div>
</template>
<script>

import Breadcrumb from "../components/Breadcrumb.vue";
import moment from "moment";


export default {
  components: {Breadcrumb},
  data() {
    return {



    };
  },
  computed: {

  },
  methods: {
    accMgt() {
      this.$router.push("/account-management");
    },
    authMgt() {
      this.$router.push("/auth-management");
    }

  },
  async mounted() {
    this.$store.dispatch("loadingHandler", true);
    //this.$store.loading = true;
    let userID = window.localStorage.getItem("userID")
    this.$store.dispatch("loadingHandler", false);
  },

};
</script>
<style lang="scss" scoped>

// * {
//   outline: red solid 1px;
// }


.home {
  height:150vh;
  background: linear-gradient(-135deg, #00abb9 20%, #fff 50%, transparent 50%) center center / 100% 100%;

  .welcomeBox {
    margin: 0px 0px;
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    h1 {
    }
  }



  .listBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0px 0px;
    padding: 20px 5%;

    .functionButton {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 80%;
      padding: 3% 13%;

      .el-button {
        width: 100%;
      }

    }


  }

  
}
::v-deep .yaxisTest{
    // font-size: 99px
    cursor: pointer;
  }
</style>
